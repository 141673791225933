<template>
    <div class="auxi">
        <div class="auxi-title">{{ $t('brand.desc84') }}</div>
        <div class="auxi-list flex" v-for="(item, index) in list" :key="index">
            <div class="list-info" >
                <div class="info-img flexcenter">
                    <img :src="item.url" alt="" :ref="`img${item.id}`" />
                    <div class="info-desc">
                        <div>{{ $t('brand.desc74') }}</div>
                    </div>
                </div>
                <div class="info-btn flexcenter" @click="handlerDown(item.psdUrl)">
                    <img src="../../../public/images/brand/downicon.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
    data() {
        return {
            list: [
                
            ]

        }
    },
    mounted() {
        this.$post(this.URL.brand.type, {
            type: 'Cap',
            page: 1,
            pageSize: 100
        }).then(res => {
            if (res.code == 0) {
               this.list = res.data.list;
            }
        })
    },
    methods: {
        handlerDown(url) {
            // let img=this.$refs[`img${id}`];
            // if(Array.isArray(img)){
            //     img=this.$refs[`img${id}`][0]
            // }
            // this.toImage(img)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.$t('brand.desc48'));
            link.click()
        },
        toImage(logo) {
            html2canvas(logo, {
                useCORS: true,
                dpi: window.devicePixelRatio * 2,
                backgroundColor: 'rgba(0,0,0,0)'
            }).then((canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                this.imgUrl = dataURL;
                if (this.imgUrl !== "") {
                    const a = document.createElement("a");
                    a.href = this.imgUrl;
                    a.download = this.$t('brand.desc48');
                    a.click();
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
* {
    font-family: 'Montserrat';
}

.auxi {
    padding: 120px 0 0 100px;

    .auxi-title {
        font-size: 16px;
        color: #fff;
        text-transform: capitalize;
    }

    .auxi-list {
        margin-top: 60px;
        flex-wrap: wrap;

        .list-info {
            flex: 0 0 370px;
            margin: 50px 30px 0 0;

            &:nth-child(-n + 3) {
                margin-top: 0;
            }
            &:hover {
                .info-img {
                    .info-desc {
                        bottom: 0;
                        transition: .5s;
                    }
                }
            }

            .info-img {
                position: relative;
                height: 265px;
                cursor: pointer;
                overflow: hidden;

                img {
                    max-width: 100%;
                }

          

                .info-desc {
                    position: absolute;
                    bottom: -100px;
                    left: 0;
                    width: 100%;
                    padding: 5px 0 14px;
                    background: rgba(0, 0, 0, 0.75);
                    transition: .5s;

                    div {
                        padding: 0 13px;
                        font-size: 12px;
                        color: rgba(255, 255, 255, 0.8);
                        line-height: 17px;
                        text-transform: capitalize;
                    }
                }
            }

            .info-btn {
                margin-top: 23px;
                height: 36px;
                background: #FDB500;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .auxi {
        padding: 155px 0 80px;
        .auxi-title{
            font-size: 14px;
            line-height: 16px;
            height: 35px;
            margin-bottom: 0;
        }
        .auxi-list{
            margin-top: 0;
            .list-info{
                flex:0 0 100px;
                width: 100px;
                margin: 0 30px 37px 0; 
                &:nth-child(2n){
                    margin-right: 0;
                }
                .info-img{
                    height: 72px;
                    img{
                        width: 100%;
                        height: 72px;
                    }
                    .info-desc{
                        padding: 2px 5px;
                        div{
                            padding: 0;
                            font-size: 5px;
                            line-height: 8px;
                        }
                    }
                }
                .info-btn{
                    margin-top: 4px;
                    height: 14px;
                    img{
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }
}
</style>