<template>
    <div class="auxi">
        <div class="auxi-title">{{ $t('brand.desc82') }}</div>
        <div class="auxi-list flex">
            <div class="list-info" v-for="(item, index) in list" :key="index">
                <div class="info-img flexcenter">
                    <img :src="item.url" alt="" :ref="`img${item.id}`" />
                </div>
                <div class="info-name">{{ item.content }}</div>
                <div class="info-btn flexcenter" @click="handlerDown(item.url)">
                    <img src="../../../public/images/brand/downicon.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
    data() {
        return {
            list: [


            ]

        }
    },
    mounted() {
        this.$post(this.URL.brand.list, {
        }).then(res => {
            if (res.code == 0) {
                res.data.records.forEach(e => {
                    if (e.type == 2) {
                        this.list.push(e)
                    }
                })

            }
        })
    },
    methods: {
        handlerDown(url) {
            // let img=this.$refs[`img${id}`];
            // if(Array.isArray(img)){
            //     img=this.$refs[`img${id}`][0]
            // }
            // this.toImage(img)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.$t('brand.desc55'));
            link.click()
        },
        toImage(logo) {
            html2canvas(logo, {
                useCORS: true,
                dpi: window.devicePixelRatio * 2,
                backgroundColor: 'rgba(0,0,0,0)'
            }).then((canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                this.imgUrl = dataURL;
                if (this.imgUrl !== "") {
                    const a = document.createElement("a");
                    a.href = this.imgUrl;
                    a.download = this.$t('brand.desc55');
                    a.click();
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
* {
    font-family: 'Montserrat';
}

.auxi {
    padding: 120px 0 0 100px;

    .auxi-title {
        font-size: 16px;
        color: #fff;
        text-transform: capitalize;
    }

    .auxi-list {
        margin-top: 60px;
        flex-wrap: wrap;

        .list-info {
            flex: 0 0 210px;
            margin: 0 30px 50px 0;
            background: url('../../../public/images/brand/posterborder.png') center no-repeat;
            background-size: 100% 100%;


            .info-img {
                position: relative;
                margin-top: 2px;
                height: 205px;
                cursor: pointer;
                overflow: hidden;

                img {
                    width: calc(100% - 2px);
                    object-fit: cover;
                }
            }

            .info-name {
                font-size: 14px;
                color: #fff;
                line-height: 20px;
                padding: 3px 10px 0;
                text-align: center;
            }

            .info-btn {
                margin-top: 43px;
                height: 36px;
                background: #FDB500;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .auxi {
        padding: 155px 0 80px;

        .auxi-title {
            font-size: 14px;
            line-height: 16px;
            height: 35px;
            margin-bottom: 0;
        }

        .auxi-list {
            margin-top: 0;

            .list-info {
                flex: 0 0 100px;
                width: 100px;
                margin: 0 30px 18px 0;

                // &:nth-child(2n){
                //     margin-right: 0;
                // }
                .info-img {
                    width: 78px;
                    margin: 2px auto 0;
                    height: 97px;

                    img {
                        width: 100%;
                        height: 97px;
                    }

                    .info-desc {
                        padding: 2px 5px;

                        div {
                            padding: 0;
                            font-size: 5px;
                            line-height: 8px;
                        }
                    }
                }

                .info-name {
                    font-size: 10px;
                    padding: 0;
                    line-height: 10px;
                }

                .info-btn {
                    margin-top: 4px;
                    height: 14px;

                    img {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }
}

@media (max-width:767px) {
    .auxi {
        .auxi-list {
            justify-content: space-between;

            .list-info {
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
}</style>