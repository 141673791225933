<template>
    <div class="planet">
        <div class="planet-title">{{ $t('brand.desc57') }}</div>
        <div class="planet-swiper">
            <img src="../../../public/images/brand/lefticon.png" alt="" class="left" @click="handlerPrev(1)" />
            <img src="../../../public/images/brand/righricon.png" alt="" class="right" @click="handlerPrev(2)" />
            <van-swipe :loop="false" :show-indicators="false" ref="swiper">
                <van-swipe-item v-for="item in list" :key="item.id">
                    <div class="banner flex">
                        <div class="banner-info" v-for="info in item" :key="info.id">
                            <div class="banner-img flexcenter">
                                <img :src="info.url" alt="" />
                            </div>
                            <div class="banner-name flexcenter">{{ info.content }}</div>
                            <div class="banner-btn flexcenter" @click="handlerDown(info.url, item.content)">
                                <img src="../../../public/images/brand/downicon.svg" alt="" />
                            </div>
                        </div>

                    </div>
                </van-swipe-item>
                <!-- <van-swipe-item>
                    <div class="banner flex">
                        <div class="banner-info" v-for="item in list1" :key="item.id">
                            <div class="banner-img flexcenter">
                                <img :src="item.img" alt="" :ref="`planet${item.id}`" />
                            </div>
                            <div class="banner-name flexcenter">{{ item.name }}</div>
                            <div class="banner-btn flexcenter" @click="handlerDown(item.id, item.name)">
                                <img src="../../../public/images/brand/downicon.svg" alt="" />
                            </div>
                        </div>

                    </div>
                </van-swipe-item> -->
            </van-swipe>
        </div>
        <div class="planet-list flex">
            <div class="banner-info" v-for="item in list1" :key="item.id">
                <div class="banner-img flexcenter">
                    <img :src="item.url" alt=""  />
                </div>
                <div class="banner-name flexcenter">{{ item.content }}</div>
                <div class="banner-btn flexcenter" @click="handlerDown(item.url, item.content)">
                    <img src="../../../public/images/brand/downicon.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
    data() {
        return {
            list: [ ],
            list1:[]
      
        }
    },
    mounted() {
        this.$post(this.URL.brand.type, {
            type: 'Planet',
            page: 1,
            pageSize: 100
        }).then(res => {
            if (res.code == 0) {
               this.list = this.getMapping(res.data.list);
               this.list1=res.data.list;
            }
        })
    },
    methods: {
        getMapping(list) {
            let len = list.length
            let newList = []
            if (len) {
                var chunk = 4
                for (var i = 0, j = len; i < j; i += chunk) {
                    newList.push(list.slice(i, i + chunk))
                }
            }
            return newList
        },
        handlerPrev(i) {
            i == 1 ? this.$refs.swiper.prev() : this.$refs.swiper.next()
        },
        handlerDown(url, name) {
         
            // let img=this.$refs[`planet${id}`];
            // if(Array.isArray(img)){
            //     img=this.$refs[`planet${id}`][0]
            // }
          
            // this.toImage(img, name)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            link.click()
        },
        toImage(logo, name) {
            html2canvas(logo, {
                useCORS: true,
                dpi: window.devicePixelRatio * 2,
                backgroundColor: 'rgba(0,0,0,0)'
            }).then((canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                this.imgUrl = dataURL;
                if (this.imgUrl !== "") {
                    const a = document.createElement("a");
                    a.href = this.imgUrl;
                    a.download = name;
                    a.click();
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
* {
    font-family: 'Montserrat';
}

.planet {
    padding: 188px 37px 0 100px;

    .planet-title {
        margin-bottom: 60px;
        font-size: 16px;
        color: #fff;
        line-height: 22px;
        text-transform: capitalize;
    }

    .planet-list {
        display: none;
    }

    .planet-swiper {
        width: 1000px;
        position: relative;
        min-height: 308px;

        .left {
            position: absolute;
            top: 50%;
            left: -62px;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .right {
            position: absolute;
            top: 50%;
            right: -62px;
            width: 24px;
            height: 24px;
            cursor: pointer;
            z-index: 5;
        }

        ::v-deep .van-swipe {
            width: 1000px;
            overflow: hidden;
        }
    }

    .banner {
        flex-wrap: wrap;

        .banner-info {
            flex: 0 0 210px;
            margin-right: 53px;
            background: url('../../../public/images/brand/boderbg1.png') center no-repeat;
            background-size: 100% 100%;

            &:last-child {
                margin-right: 0;
            }

            .banner-img {
                width: 210px;

                img {
                    width: 210px;
                    height: 210px;
                }
            }

            .banner-name {
                padding-bottom: 43px;
                font-size: 14px;
                color: #fff;
                line-height: 19px;
                text-transform: capitalize;
            }

            .banner-btn {
                height: 36px;
                background: #FDB500;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .planet {
        padding: 155px 0 80px;

        .planet-title {
  
            height: 35px;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 16px;
        }

        .planet-swiper {
            display: none;
        }

        .planet-list {
            display: flex;
            padding: 0 32px;
            flex-wrap: wrap;
            justify-content: space-between;
            .banner-info {
                flex: 0 0 80px;
                margin-right: 20px;
                margin-bottom: 24px;
                background: url('../../../public/images/brand/boderbg1.png') center no-repeat;
                background-size: 100% 100%;

                &:last-child {
                    margin-right: 0;
                }

                .banner-img {
                    width: 80px;

                    img {
                        width: 80px;
                        height: 80px;
                    }
                }

                .banner-name {
                    padding-bottom: 21px;
                    font-size: 10px;
                    color: #fff;
                    line-height: 11px;
                    text-transform: capitalize;
                }

                .banner-btn {
                    height: 13px;
                    background: #FDB500;
                    cursor: pointer;

                    img {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }
}
@media (max-width:767px) {
    .planet {
        padding: 155px 0 80px;
        .planet-list {
            justify-content: center;
            .banner-info{
                &:nth-child(2n){
                    margin-right: 0;
                }
            }
        }
    }
}
</style>