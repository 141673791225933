<template>
    <div class="brand">
        <el-container class="layout-container-demo" style="height: 100vh">
            <el-aside width="300px">
                <el-scrollbar>
                    <div class="brand-logo">
                        <img src="../../../public/images/logo.png" alt="" @click="$router.push('/home')" />
                    </div>
                    <div class="brand-nav">
                        <div class="flex" v-for="(item, index) in oldlist" :key="item.id"
                            :class="{ tabact: act == item.name }" @click="handlerTab(item.id, item.name)">
                            <p>{{ item.name }}</p>
                            <p
                                :style="{ marginTop: item.name == $t('brand.desc53') || item.name == $t('brand.desc51') ? '35px' : '17px' }">
                            </p>
                        </div>
                    </div>
                    <div class="brand-resource" @click="$router.push('/brand/component')">{{ $t('brand.desc1') }}</div>
                </el-scrollbar>
            </el-aside>
            <el-container>
                <el-header>
                    <div class="topimg">
                        <img src="../../../public/images/logo.png" alt="" @click="$router.push('/home')" />
                    </div>
                    <div class="brand-top flex">
                        <div @click="$router.push('/brand/component')">{{ $t('brand.desc1') }}</div>
                        <div class="topact" @click="$router.push('/brand/resource')">{{ $t('brand.desc2') }}</div>
                    </div>
                    <div class="brand-select">
                        <el-select v-model="activieName">
                            <el-option v-for="item in oldlist" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </div>
                </el-header>
                <el-main><el-scrollbar>
                        <div class="brand-con">
                            <component :is="activieName"></component>
                        </div>
                    </el-scrollbar>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import Logo from './logo.vue';
import Planet from './planet.vue';
import Land from './land.vue';
import Auxiliary from './auxiliary.vue';
import Flag from './flag.vue';
import Lanyard from './lanyard.vue';
import Shirt from './shirt.vue';
import Bag from './bag.vue';
import Cap from './cap.vue';
import Pen from './pen.vue';
import Notepad from './notepad.vue';
import Bottle from './bottle.vue';
import Brochure from './brochure.vue'
import Bunting from './bunting.vue';
import Poster from './poster.vue';
import Newsletter from './newsletter.vue';
export default {
    components: {
        Logo,
        Planet,
        Land,
        Auxiliary,
        Flag,
        Lanyard,
        Shirt,
        Bag,
        Cap,
        Pen,
        Notepad,
        Bottle,
        Brochure,
        Bunting,
        Poster,
        Newsletter
    },
    data() {
        return {
            activieName: 'Logo',
            act: this.$t('brand.desc40'),
            list: [
                {
                    id: 'Logo',
                    name: this.$t('brand.desc40')
                },
                {
                    id: 'Planet',
                    name: this.$t('brand.desc41')
                },
                {
                    id: 'Land',
                    name: this.$t('brand.desc42')
                },
                {
                    id: 'Auxiliary',
                    name: this.$t('brand.desc43')
                },
                {
                    id: 'Flag',
                    name: this.$t('brand.desc44')
                },
                {
                    id: 'Lanyard',
                    name: this.$t('brand.desc45')
                },
            ],
            oldlist: [
                {
                    id: 'Logo',
                    name: this.$t('brand.desc40')
                },
                {
                    id: 'Planet',
                    name: this.$t('brand.desc41')
                },
                {
                    id: 'Land',
                    name: this.$t('brand.desc42')
                },
                {
                    id: 'Auxiliary',
                    name: this.$t('brand.desc43')
                },
                {
                    id: 'Flag',
                    name: this.$t('brand.desc44')
                },
                {
                    id: 'Lanyard',
                    name: this.$t('brand.desc45')
                },
                {
                    id: 'Shirt',
                    name: this.$t('brand.desc46')
                },
                {
                    id: 'Bag',
                    name: this.$t('brand.desc47')
                },
                {
                    id: 'Cap',
                    name: this.$t('brand.desc48')
                },
                {
                    id: 'Pen',
                    name: this.$t('brand.desc49')
                },
                {
                    id: 'Notepad',
                    name: this.$t('brand.desc50')
                },
                {
                    id: 'Bottle',
                    name: this.$t('brand.desc51')
                },
                {
                    id: 'Brochure',
                    name: this.$t('brand.desc52')
                },
                {
                    id: 'Bunting',
                    name: this.$t('brand.desc53')
                },
                {
                    id: 'Poster',
                    name: this.$t('brand.desc54')
                },
                {
                    id: 'Newsletter',
                    name: this.$t('brand.desc55')
                }
            ],
            addlen: 0
        }
    },
    methods: {
        handlerTab(id, name) {

            this.act = name;
            this.activieName = id;
            let index = this.oldlist.findIndex(item => item.id == id);
            let list = [];
            if (index < 4) {
                list = this.oldlist.slice(0, 6)
            } else if (index > 3 && index < 11) {
                list = this.oldlist.slice(index - 2, index + 4)

            } else {
                list = this.oldlist.slice(10, 16)
            }

            this.list = list;

        }
    }
}
</script>

<style lang="less" scoped>
div,
p,
span {
    font-family: 'Montserrat';
}

.brand {
    padding: 0 100px;
    width: 100%;
    min-height: 100vh;
    background: url('../../../public/images/brand/bg1.png') right bottom no-repeat;
    background-size: 800px 702px;
    background-color: #000;
    vertical-align: middle;

    ::v-deep .el-aside {
        position: relative;
        padding-top: 35px;
        height: 100vh;
        border-right: 1px solid rgba(118, 118, 118, .4);
        box-sizing: border-box;
        overflow: initial;

        .el-scrollbar__wrap--hidden-default,
        .el-scrollbar {
            overflow: initial;
        }

        .brand-logo {
            width: 189px;

            img {
                width: 189px;
                height: 30px;
                cursor: pointer;
            }
        }

        .brand-nav {
            margin-top: 120px;
            max-height: 65vh;
            overflow-y: scroll;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                background-color: rgba(0, 0, 0, .2);
            }

            /*定义滚动条轨道
 内阴影+圆角*/
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0);
            }

            /*定义滑块
     内阴影+圆角*/
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: rgba(255, 255, 255, .2);
            }

            div {
                position: relative;
                margin-bottom: 40px;
                font-size: 16px;
                color: #FFFFFF;
                text-transform: capitalize;
                cursor: pointer;
                line-height: 16px;

                &:last-child {
                    margin-top: 0;
                }

                p {
                    &:first-child {
                        max-width: 80%;
                        padding-right: 20px;
                    }

                    &:last-child {
                        flex: 1;
                        margin-top: 17px;
                        height: 1px;
                        background: rgba(255, 255, 255, .3);
                        display: none;
                    }
                }

                &:hover {
                    font-size: 36px;
                    line-height: 36px;
                    color: #FDB500;

                    // &::after {
                    //     content: '';
                    //     position: absolute;
                    //     top: 50%;
                    //     right: -5px;
                    //     width: 10px;
                    //     height: 10px;
                    //     border-radius: 50%;
                    //     background: #fff;
                    //     transform: translateY(-50%);
                    // }

                    p {
                        &:last-child {
                            display: block;
                        }
                    }
                }
            }

            .tabact {
                font-size: 36px;
                line-height: 36px;
                color: #FDB500;

                // &::after {
                //     content: '';
                //     position: absolute;
                //     top: 50%;
                //     right: -5px;
                //     width: 10px;
                //     height: 10px;
                //     border-radius: 50%;
                //     background: #fff;
                //     transform: translateY(-50%);
                // }

                p {
                    &:last-child {
                        display: block;
                    }
                }

            }
        }

        .brand-resource {
            position: relative;
            position: absolute;
            bottom: 80px;
            left: 0;
            font-size: 14px;
            color: #fff;
            line-height: 14px;
            padding-left: 88px;
            text-transform: uppercase;
            font-weight: 300;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 60px;
                height: 1px;
                background: #D9D9D9;
                transform: translateY(-50%);
            }

            &:hover {
                color: #FDB500;
            }
        }
    }

    .brand-top {
        padding-top: 35px;
        justify-content: flex-end;
        font-size: 18px;
        color: #fff;
        line-height: 30px;
        cursor: pointer;

        div {

            text-transform: capitalize;

            &:last-child {
                margin-left: 70px;
            }

            &:hover {
                color: #FDB500;
            }
        }

        .topact {
            color: #FDB500;
        }
    }

    .topimg,
    .brand-select {
        display: none;
    }

    ::v-deep .el-main {
        padding: 0 0 15px 0;
    }
}

@media (max-width:1200px) {
    .brand {
        padding: 0 57px;
        background: url('../../../public/images/brand/samllbg1.png') center no-repeat;
        background-size: 100% 100%;

        ::v-deep .el-aside {
            display: none;
        }

        ::v-deep .el-header {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: fit-content;
            padding: 0 57px;
            background: #000;
            z-index: 5;
        }

        .topimg {
            padding-top: 23px;
            display: block;

            img {
                width: 112px;
                height: 18px;
            }
        }

        .brand-select {
            display: block;
            margin-top: 34px;
            padding-bottom: 18px;

            ::v-deep .el-select {
                width: 159px;

                .el-input__wrapper {
                    position: relative;
                    background-color: rgba(118, 118, 118, 0);
                    border: 0.5px solid #B8B8B8;
                    box-shadow: none;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 6px;
                        right: 7px;
                        width: 8px;
                        height: 8px;
                        background: url('../../../public/images/brand/downicon.png') no-repeat;
                        background-size: 8px 8px;
                        z-index: 56;
                        transition: .5s;
                    }
                }

                .el-input__inner {
                    position: relative;
                    color: #fff;
                    height: 21px;
                    font-size: 14px;
                    text-transform: capitalize;
                    line-height: 21px;
                }

                .is-focus {
                    &::after {
                        transform: rotate(-180deg);
                        transition: .5s;
                    }
                }

                .el-input__suffix {
                    display: none;
                }
            }
        }

        .brand-top {
            padding-top: 25px;
            font-size: 14px;
            justify-content: space-between;
            line-height: 13px;
        }

        ::v-deep .el-main {
            padding: 0;
        }
    }
}</style>