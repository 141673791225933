<template>
    <div class="auxi">
        <div class="auxi-title">{{ $t('brand.desc66') }}</div>
        <div class="auxi-list flex">
            <div class="list-info" v-for="info in list" :key="info.id">
                    <div class="info-img flexcenter">
                        <img :src="info.url" alt="" :ref="`img${info.id}`" />
                    </div>
                    <div class="info-btn flexcenter" @click="handlerDown(info.url,info.type)">
                        <img src="../../../public/images/brand/downicon.svg" alt="" />
                    </div>
                </div>
            
        </div>
        <!-- <div class="auxi-list flex h5list">
            
                <div class="list-info" v-for="info in list1" :key="info.id">
                    <div class="info-img flexcenter">
                        <img :src="info.img" alt="" :ref="`img${info.id}`" />
                    </div>
                    <div class="info-btn flexcenter" @click="handlerDown(info.id)">
                        <img src="../../../public/images/brand/downicon.svg" alt="" />
                    </div>
                </div>
            
        </div> -->
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
    data() {
        return {
            list: [
            ]
        }
    },
    mounted() {
        this.$post(this.URL.brand.type,{
            type:'Auxiliary',
            page:1,
            pageSize:100
        }).then( res =>{
            if(res.code==0){
            this.list=res.data.list;
            }
        })
    },
    methods: {
        handlerDown(url,name) {
            // let img=this.$refs[`img${id}`];
            // if(Array.isArray(img)){
            //     img=this.$refs[`img${id}`][0]
            // }
            // this.toImage(img)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            link.click()
        },
        toImage(logo) {
            html2canvas(logo, {
                useCORS: true,
                dpi: window.devicePixelRatio * 2,
                backgroundColor: 'rgba(0,0,0,0)'
            }).then((canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                this.imgUrl = dataURL;
                if (this.imgUrl !== "") {
                    const a = document.createElement("a");
                    a.href = this.imgUrl;
                    a.download = this.$t('brand.desc43');
                    a.click();
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
* {
    font-family: 'Montserrat';
}

.auxi {
    padding: 120px 0 0 100px;

    .auxi-title {
        font-size: 16px;
        color: #fff;
        text-transform: capitalize;
    }

    .auxi-list {
        margin-top: 60px;
        flex-wrap: wrap;

        // .list-con {
        //     margin-top: 50px;

        //     &:first-child {
        //         margin-top: 0;
        //     }
        // }

        .list-info {
            flex: 0 0 251px;
            margin: 0 30px 50px 0;

            .info-img {
                height: 178px;
                background: url('../../../public/images/brand/border2.png') center no-repeat;
                background-size: 100% 100%;

                img {
                    max-width: 100%;
                }
            }

            .info-btn {
                margin-top: 10px;
                height: 36px;
                background: #FDB500;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    .h5list{
        display: none;
    }
}

@media (max-width:1200px) {
    .auxi {
        padding: 155px 0 80px;

        .auxi-title {
            font-size: 14px;
            line-height: 16px;
            height: 35px;
            margin-bottom: 0;
        }

        .list-con {
            margin-top: 0;
            flex-wrap: wrap;
            display: none;
            &:first-child {
                margin-top: 0;
            }
        }

        .auxi-list {
            margin-top: 0;
            justify-content: space-between;

            .list-info {
                flex: 0 0 100px;
                width: 100px;
                margin: 0 30px 37px 0;

                .info-img {
                    margin-top: 2px;
                    width: 100%;
                    height: 70px;

                    img {
                        height: 70px;
                    }
                }

                .info-btn {
                    margin-top: 3px;
                    height: 14px;

                    img {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
        .h5list{
            display: flex;
        }
    }
}

@media (max-width:1200px) {
    .auxi {
        .auxi-list {}
    }
}</style>