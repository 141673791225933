<template>
    <div class="logo">
        <div class="logo-title">{{ $t('brand.desc56') }}</div>
        <div class="logo-samll flex">
            <div class="samll-logo" v-for="item in list" :key="item.id">
                <div class="down-img flexcenter">
                    <img :src="item.url" alt="" ref="logo1" />
                </div>
                <div class="down-btn flexcenter" @click="handlerDown(item.url)">
                    <img src="../../../public/images/brand/downicon.svg" alt="" />
                </div>
            </div>
         
        </div>
        <div class="logo-big flex">
            <div class="big-logo" v-for="item in list1" :key="item.id">
                <div class="down-img flexcenter">
                    <img :src="item.url" alt="" ref="logo3" />
                </div>
                <div class="down-btn flexcenter" @click="handlerDown(item.url)">
                    <img src="../../../public/images/brand/downicon.svg" alt="" />
                </div>
            </div>
            <!-- <div class="big-logo">
                <div class="down-img flexcenter">
                    <img src="../../../public/images/brand/logo5.png" alt="" ref="logo3" />
                </div>
                <div class="down-btn flexcenter" @click="handlerDown(3)">
                    <img src="../../../public/images/brand/downicon.svg" alt="" />
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
    data() {
        return {
            list: [],
            list1:[]
        }
    },
    mounted() {
        this.$post(this.URL.brand.type, {
            type: 'Logo',
            page: 1,
            pageSize: 100
        }).then(res => {
            if (res.code == 0) {
                this.list=res.data.list.splice(0,4);
                this.list1=res.data.list;
            //    this.list = this.getMapping(res.data.list);
            //    this.list1=res.data.list;
            }
        })
    },
    methods: {
        handlerDown(url) {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.$t("brand.desc40"));
            link.click()
            // this.toImage(this.$refs[`logo${i}`])
        },
        toImage(logo) {
            html2canvas(logo, {
                useCORS: true,
                dpi: window.devicePixelRatio * 2,
                backgroundColor: 'rgba(0,0,0,0)'
            }).then((canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                this.imgUrl = dataURL;
                if (this.imgUrl !== "") {
                    const a = document.createElement("a");
                    a.href = this.imgUrl;
                    a.download = this.$t("brand.desc40");
                    a.click();
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
* {
    font-family: 'Montserrat';
}

.logo {
    padding-top: 134px;

    .logo-title {
        padding-left: 100px;
        font-size: 16px;
        color: #fff;
        text-transform: capitalize;
    }

    .logo-samll {
        padding: 63px 0 30px 100px;
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        flex-wrap: wrap;

        .samll-logo {
            flex: 0 0 150px;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }

            .down-img {
                width: 150px;
                height: 150px;
                background: url('../../../public/images/brand/logoborder.png') center no-repeat;
                background-size: 100% 100%;
                box-sizing: border-box;

                img {

                    width: 54px;
                    height: 34px;
                }
            }

            .down-btn {
                height: 36px;
                background: #FDB500;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }
            }

            &:nth-child(2n) {
                .down-img {
                    background: #fff;
                }
            }

            &:nth-child(-n + 2) {
                .down-img {
                    img {
                        width: 114px;
                        height: 54px;
                    }
                }
            }
        }
    }

    .logo-big {
        padding: 48px 0 0 100px;

        .big-logo {
            flex: 0 0 267px;
            margin-right: 26px;

            &:nth-child(2) {
                .down-img {
                    background: #fff;
                }
            }

            .down-img {
                width: 267px;
                height: 150px;
                background: url('../../../public/images/brand/logoborder1.png') center no-repeat;
                background-size: 100% 100%;
                box-sizing: border-box;

                img {

                    width: 162px;
                    height: 26px;
                }
            }

            .down-btn {
                height: 36px;
                background: #FDB500;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .logo {
        padding: 155px 0 10px;

        .logo-title {
            padding-left: 0;
            font-size: 14px;
            line-height: 16px;
        }

        .logo-samll {
            padding: 0 0 27px 0;
            justify-content: center;
            border-bottom: 0;
            .samll-logo {
                flex: 0 0 50%;
                width: 50%;
                margin-top: 27px;
                margin-right: 0;
                .down-img {
                    width: 80px;
                    height: 80px;
                    margin: 0 auto;
                    img {
                        width: 28px;
                        height: 18px;
                    }
                }
                .down-btn{
                    margin: 0 auto;
                    width: 80px;
                    height: 20px;
                    img{
                        width: 11px;
                        height: 11px;
                    }
                }
                &:nth-child(2n){
                    margin-right: 0;
                }
                &:nth-child(-n + 2) {
                    .down-img {
                        img {
                            width: 60px;
                            height: 29px;
                        }
                    }
                }
            }
        }
        .logo-big{
            padding: 0;
            display: block;
            .big-logo{
               
                width: 144px;
                margin:0 auto 27px;
                .down-img{
                    width: 144px;
                    height: 80px;
                    img{
                        width: 84px;
                        height: 13px;
                    }
                }
                .down-btn{
                   
                    height: 20px;
                    img{
                        width: 11px;
                        height: 11px;
                    }
                }
            }
        }
    }
}</style>