<template>
    <div class="auxi">
        <div class="auxi-title">{{ $t('brand.desc81') }}</div>
        <div class="auxi-list flex">
            <div class="list-info" v-for="(item, index) in list" :key="index">
                <div class="info-img flexcenter">
                    <img :src="item.url" alt="" :ref="`img${item.id}`" />

                </div>
                <div class="info-name">{{ item.content }}</div>
                <div class="info-btn flexcenter" @click="handlerShow(item)">
                    <img src="../../../public/images/brand/downicon.svg" alt="" />
                </div>
            </div>
        </div>
        <!--  -->
        <van-popup v-model:show="showMore" :overlay="false">
            <div class="modal">
                <div class="modal-title flex">
                    <div>{{ info.content }}</div>
                    <img src="../../../public/images/brand/closeicon.png" alt="" @click="showMore = false" />
                </div>
                <div class="modal-banner">
                    <img src="../../../public/images/brand/lefticon.png" alt="" class="left" @click="handlerPrev(1)" />
                    <img src="../../../public/images/brand/righricon.png" alt="" class="right" @click="handlerPrev(2)" />
                    <div class="banner-con" v-if="ismobile == 0">
                        <van-swipe :loop="false" :show-indicators="false" ref="swiper">
                            <van-swipe-item v-for="(item, index) in moreList.type1" :key="item.id" v-if="lend == 1">
                                <div class="subbanner flex">
                                    <div class="banner-info" v-for="info in moreList.type2[index]" :key="info.id">
                                        <div class="banner-img flexcenter">
                                            <img :src="info.url" alt="" :ref="`planet${info.id}`" />
                                        </div>
                                        <div class="banner-btn flexcenter" @click="handlerDown(info.url)">
                                            <img src="../../../public/images/brand/downicon.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="banner flex">
                                    <div class="banner-info" v-for="info in item" :key="info.id">
                                        <div class="banner-img flexcenter">
                                            <img :src="info.url" alt="" :ref="`planet${info.id}`" />
                                        </div>
                                        <div class="banner-btn flexcenter" @click="handlerDown(info.url)">
                                            <img src="../../../public/images/brand/downicon.svg" alt="" />
                                        </div>
                                    </div>
                                </div>

                            </van-swipe-item>
                            <van-swipe-item v-for="(item, index) in moreList.type2" :key="item.id" v-if="lend == 2">

                                <div class="subbanner flex">
                                    <div class="banner-info" v-for="info in item" :key="info.id">
                                        <div class="banner-img flexcenter">
                                            <img :src="info.url" alt="" :ref="`planet${info.id}`" />
                                        </div>
                                        <div class="banner-btn flexcenter" @click="handlerDown(info.url)">
                                            <img src="../../../public/images/brand/downicon.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="banner flex">
                                    <div class="banner-info" v-for="info in moreList.type1[index]" :key="info.id">
                                        <div class="banner-img flexcenter">
                                            <img :src="info.url" alt="" :ref="`planet${info.id}`" />
                                        </div>
                                        <div class="banner-btn flexcenter" @click="handlerDown(info.url)">
                                            <img src="../../../public/images/brand/downicon.svg" alt="" />
                                        </div>
                                    </div>
                                </div>

                            </van-swipe-item>
                        </van-swipe>
                    </div>
                    <div class="h5banner-con" v-else>
                        <van-swipe :loop="false" :show-indicators="false" ref="swiper">
                            <van-swipe-item v-for="(item, index) in h5List.type1" :key="item.id" v-if="h5lend == 1">

                                <div class="subbanner" v-if="h5List.type2[index] != undefined">
                                    <div class="banner-info">
                                        <div class="banner-img flexcenter">
                                            <img :src="h5List.type2[index].url" alt=""
                                                :ref="`planet${h5List.type2[index].id}`" />
                                        </div>
                                        <div class="banner-btn flexcenter" @click="handlerDown(h5List.type2[index].url)">
                                            <img src="../../../public/images/brand/downicon.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="banner">
                                    <div class="banner-info">
                                        <div class="banner-img flexcenter">
                                            <img :src="item.url" alt="" :ref="`planet${item.id}`" />
                                        </div>
                                        <div class="banner-btn flexcenter" @click="handlerDown(item.url)">
                                            <img src="../../../public/images/brand/downicon.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </van-swipe-item>
                            <van-swipe-item v-for="(item, index) in h5List.type2" :key="item.id" v-if="h5lend == 2">
                                <div class="subbanner">
                                    <div class="banner-info">
                                        <div class="banner-img flexcenter">
                                            <img :src="item.url" alt="" :ref="`planet${item.id}`" />
                                        </div>
                                        <div class="banner-btn flexcenter" @click="handlerDown(item.url)">
                                            <img src="../../../public/images/brand/downicon.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="banner" v-if="h5List.type1[index] != undefined">
                                    <div class="banner-info">
                                        <div class="banner-img flexcenter">
                                            <img :src="h5List.type1[index].url" alt=""
                                                :ref="`planet${h5List.type1[index].id}`" />
                                        </div>
                                        <div class="banner-btn flexcenter" @click="handlerDown(h5List.type1[index].url)">
                                            <img src="../../../public/images/brand/downicon.svg" alt="" />
                                        </div>
                                    </div>
                                </div>

                            </van-swipe-item>
                        </van-swipe>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
    data() {
        return {
            showMore: false,
            list: [],
            info: {},
            moreList: [],
            lend: 1,
            h5List: {},
            h5lend: 1,
            ismobile: 0

        }
    },
    mounted() {
        this.ismobile = localStorage.getItem('ismobile')
        this.$post(this.URL.brand.list, {
        }).then(res => {
            if (res.code == 0) {
                res.data.records.forEach(e => {
                    if (e.type == 1) {
                        this.list.push(e);

                    }
                })

            }
        })

    },
    methods: {
        getinfo() {
            this.$post(this.URL.brand.info, {
                newImageId: this.info.id,
                pageSize: 1000
            }).then(res => {
                if (res.code == 0) {
                    this.h5lend = 1;
                    this.lend = 1;
                    let chunk = 4, result = {
                        type2: [],
                        type1: []
                    }, type1 = [], type2 = [];
                    res.data.records.forEach(e => {
                        if (e.type == 1) {
                            type1.push(e)
                        } else {
                            type2.push(e)
                        }
                    })
                    if (type2.length > type1.length) {
                        this.h5lend = 2;
                    }

                    this.h5List = {
                        type1: type1,
                        type2: type2
                    }

                    for (let i = 0, j = type2.length; i < j; i += chunk) {
                        result.type2.push(type2.slice(i, i + chunk));

                    }
                    for (let i = 0, j = type1.length; i < j; i += chunk) {
                        result.type1.push(type1.slice(i, i + chunk));
                    }
                    this.moreList = result;
                    if (this.moreList.type2.length > this.moreList.type1.length) {
                        this.lend = 2;
                    }
                    this.showMore = true;
                }
            })
        },
        handlerPrev(i) {
            i == 1 ? this.$refs.swiper.prev() : this.$refs.swiper.next()
        },
        handlerShow(info) {
            this.info = info;
            this.moreList = [];
            this.h5List = {}
            this.getinfo()

        },
        handlerDown(url) {
            // let img=this.$refs[`planet${id}`];
            // if(Array.isArray(img)){
            //     img=this.$refs[`planet${id}`][0]
            // }
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.info.title);
            link.click()
        },
        toImage(logo) {
            // const a = document.createElement("a");
            //     a.href =logo;
            //     a.download = '111';
            window.open(logo)
            // html2canvas(logo, {
            //     useCORS: true,
            //     dpi: window.devicePixelRatio * 2,
            //     backgroundColor: 'rgba(0,0,0,0)'
            // }).then((canvas) => {
            //     let dataURL = canvas.toDataURL("image/png");
            //     this.imgUrl = dataURL;
            //     if (this.imgUrl !== "") {
            //         const a = document.createElement("a");
            //         a.href = this.imgUrl;
            //         a.download = this.info.title;
            //         a.click();
            //     }
            // });
        },
    }
}
</script>

<style lang="less" scoped>
* {
    font-family: 'Montserrat';
}

.auxi {
    padding: 120px 0 0 100px;

    .auxi-title {
        font-size: 16px;
        color: #fff;
        text-transform: capitalize;
    }

    .auxi-list {
        margin-top: 60px;
        flex-wrap: wrap;

        .list-info {
            flex: 0 0 210px;
            margin: 50px 30px 0 0;
            background: url('../../../public/images/brand/posterborder.png') center no-repeat;
            background-size: 100% 100%;

            &:nth-child(-n + 3) {
                margin-top: 0;
            }

            .info-img {
                position: relative;
                margin-top: 2px;
                height: 205px;
                cursor: pointer;
                overflow: hidden;

                img {
                    max-width: 100%;
                    height: 205px;
                }
            }

            .info-name {
                font-size: 14px;
                color: #fff;
                line-height: 20px;
                padding: 3px 10px 0;
                text-align: center;
            }

            .info-btn {
                margin-top: 43px;
                height: 36px;
                background: #FDB500;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.modal {
    width: 1348px;
    padding: 42px 35px 127px;
    border-radius: 15px;
    border: 1px solid #FDB500;
    background: rgba(0, 0, 0, 0.90);

    .modal-title {
        padding: 0 40px 0 18px;
        font-size: 36px;
        color: #FDB500;
        line-height: 50px;
        justify-content: space-between;

        img {
            width: 20px;
            height: 20px;
            margin-top: 15px;
            cursor: pointer;
        }
    }

    .modal-banner {
        position: relative;
        margin-top: 35px;

        .left {
            position: absolute;
            top: 50%;
            left: 0;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .right {
            position: absolute;
            top: 50%;
            right: 0;
            width: 24px;
            height: 24px;
            cursor: pointer;
            z-index: 5;
        }

        .h5banner-con {
            display: none;
        }

        ::v-deep .van-swipe {
            width: 1123px;
            margin: 0 auto;
        }

        .banner {
            margin-top: 62px;

            .banner-info {
                flex: 0 0 226px;
                margin-right: 72px;
                background: url('../../../public/images/brand/boderbg1.png') center no-repeat;
                background-size: 100% 100%;

                &:last-child {
                    margin-right: 0;
                }

                .banner-img {
                    width: 226px;

                    img {
                        width: 226px;
                        height: 134px;
                    }
                }

                .banner-btn {
                    height: 32px;
                    background: #FDB500;
                    cursor: pointer;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .subbanner {
            .banner-info {
                flex: 0 0 190px;
                margin-right: 121px;
                background: url('../../../public/images/brand/boderbg1.png') center no-repeat;
                background-size: 100% 100%;

                &:last-child {
                    margin-right: 0;
                }

                .banner-img {
                    width: 190px;

                    img {
                        width: 190px;
                        height: 237px;
                    }
                }

                .banner-btn {
                    height: 32px;
                    background: #FDB500;
                    cursor: pointer;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .auxi {
        padding: 155px 0 80px;

        .auxi-title {
            font-size: 14px;
            line-height: 16px;
            height: 35px;
            margin-bottom: 0;
        }

        .auxi-list {
            margin-top: 0;

            .list-info {
                flex: 0 0 100px;
                width: 100px;
                margin: 0 30px 18px 0;

                // &:nth-child(2n){
                //     margin-right: 0;
                // }
                .info-img {
                    width: 78px;
                    margin: 2px auto 0;
                    height: 97px;

                    img {
                        width: 100%;
                        height: 97px;
                    }

                    .info-desc {
                        padding: 2px 5px;

                        div {
                            padding: 0;
                            font-size: 5px;
                            line-height: 8px;
                        }
                    }
                }

                .info-name {
                    font-size: 10px;
                    padding: 0;
                    line-height: 10px;
                }

                .info-btn {
                    margin-top: 4px;
                    height: 14px;

                    img {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }

    .modal {
        width: calc(100vw - 27px);
        padding: 14px 19px 35px;

        .modal-title {
            padding: 0;
            font-size: 16px;
            line-height: 18px;

            img {
                width: 14px;
                height: 14px;
                margin-top: 2px;
            }
        }

        .modal-banner {
            .left {
                width: 14px;
                height: 14px;
            }

            .right {
                width: 14px;
                height: 14px;
            }

            .h5banner-con {
                display: block;

                ::v-deep .van-swipe {
                    width: 162px;
                    margin: 0 auto;
                }

                .subbanner {
                    width: 100%;

                    .banner-info {
                        width: 81px;
                        margin: 0 auto;

                        .banner-img {
                            width: 81px;

                            img {
                                width: 100%;
                                height: 101px;
                                object-fit: cover;
                            }
                        }

                        .banner-btn {
                            height: 13px;

                            img {
                                width: 7px;
                                height: 7px;
                            }
                        }
                    }
                }

                .banner {
                    margin-top: 21px;

                    .banner-info {
                        width: 162px;
                        margin: 0 auto;

                        .banner-img {
                            width: 162px;

                            img {
                                width: 100%;
                                height: 90px;
                                object-fit: cover;
                            }
                        }

                        .banner-btn {
                            height: 13px;

                            img {
                                width: 7px;
                                height: 7px;
                            }
                        }
                    }
                }
            }
        }

        .banner-con {
            display: none;
        }



    }
}

@media (max-width:767px) {
    .auxi {
        .auxi-list {
            .list-info {
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>